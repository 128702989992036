import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Link2 from  '@material-ui/core/Link'

import HighscoreEintrag from '../highscore/HighscoreEintrag'


export default function MeinStart() {
    const meinWert = parseInt(window.name);
    return (
        <div align="center">
            <div>
                {(meinWert > 0) ? <HighscoreEintrag /> : null}
            </div>
            <div>
                <b>Fertig? Los:<br /><br /></b>
                <Link to="/snake">
                    <React.Fragment>
                        <Link2 underline="none">
                            <Button variant="contained" color="primary">Spiel starten</Button>
                        </Link2>
                    </React.Fragment>
                </Link>
            </div>
        </div>

    )

}
    