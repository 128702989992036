import * as React from 'react';

export default function gibtsnicht() {
  return (
    <p className="gibtsnicht">
        <b>Hinweis: Hier führt leider nicht jede theoretisch mögliche Adressleisten-Zeichenkombination zu einer interessanten Seite.</b>
        <br />
        (404)
    </p>
  );
}