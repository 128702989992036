import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Snake from './komponenten/snake/App'
import * as serviceWorker from './serviceWorker';

window.name = '0';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
