import React, { useCallback } from 'react';

import { withRouter, RouteComponentProps } from 'react-router';
import { AppBar, IconButton, Menu, MenuItem, Typography, Button, Paper } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { Toolbar } from './Nav.styles';
import { makeStyles } from '@material-ui/core/styles';
import Link2 from  '@material-ui/core/Link'

interface Props {
  onLogout: () => void;
}

function Nav() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  // ne menge für paper-menü, bringt aber kaum was
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <AppBar>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Menu"
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="navigation-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <div className={classes.root}>
          <Paper className={classes.paper}>
            <MenuItem onClick={handleMenuClose}>
              <Link to="/">
                <Link2 underline='hover'>
                  Start
                </Link2>
              </Link>
            </MenuItem>
            <MenuItem onClick={handleMenuClose}>
              <Link to="/snake">
                <Link2 underline='none'>
                  Spiel
                </Link2>
              </Link>
          </MenuItem>
          <MenuItem onClick={handleMenuClose}>
            <Link to="/highscore">
              <Link2 underline='none'>
                Bestenliste
              </Link2>
            </Link>
          </MenuItem>
          </Paper>
          </div>
        </Menu>
        <Typography variant="h6" >
                Alles Super
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Logout"
          href="https://www.onlinewahn.de/ende.htm"

          //onClick={aktion()}
          
        >
          
          <ExitIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

 function aktion(){
    console.log("ich weiß nicht, ob es etwas tun soll (aktion!)");
    return ' ';
    
}

export default withRouter(Nav);
