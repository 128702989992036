import React, {Component, FormEvent }  from 'react'
import Button from '@material-ui/core/Button';
import Link2 from  '@material-ui/core/Link'

interface Props {
  onEintrag: (name: string, punkte: string, datum: string, kommentar: string) => void;
}

export default class App extends Component<Props> {
  name = React.createRef<HTMLInputElement>()
  punkte = '-'
  datum = '-'
  kommentar = React.createRef<HTMLInputElement>()

  componentDidMount() {
    this.name.current!.focus();
  }

  handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    console.log('teste Eintrag in handleSubmit');
    event.preventDefault();
    this.props.onEintrag(
      this.name.current!.value,
      this.punkte,
      this.datum,
      this.kommentar.current!.value
    )
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <label htmlFor="">Dein anzuzeigender Name:</label><br />
            <input type="text" id="name" ref={this.name} /><br /><br />
          </div>
          <div>
            <label htmlFor="">Dein anzuzeigender Kommentar:</label><br />
            <input type="text" id="kommentar" ref={this.kommentar} /><br />
          </div>
          <br />
          <React.Fragment>
             <Link2 underline="none">
                <Button variant="contained" color="primary" type="submit">Eintragen</Button>
             </Link2>
          </React.Fragment>
          <br /><br /><br />
        </form>
      </div>    
    )
  }
}