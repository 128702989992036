import React from 'react';
import HighscoreListe from './HighscoreListe'
import HighscoreDatensatz from './HighscoreDatensatz'

interface Props {
  highscores: HighscoreDatensatz[];
}

export default function HighscoreListContainer(props: Props) {
  return (
    <div className="beste">
      <h1>Bestenliste - Straße der Besten</h1>
        <HighscoreListe highscores={props.highscores} />
    </div>
  );
}
