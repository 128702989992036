import React, {Component} from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'

import './App.css'
import Einstieg from './komponenten/Einstieg'
import GibtsNicht from './komponenten/shared/404'
import Navigation from './komponenten/Nav'
import Snake from './komponenten/snake/App'
import Spiel from './komponenten/snake/MeinStart'
import HighscoreListe from './komponenten/highscore/HighscoreApi'
import HighscoreEintrag from './komponenten/highscore/HighscoreEintrag'

export default class App extends Component {

    render() {
        return (
            <div>
                <br /><br /><br /><br />
                <Router>
                    <Navigation />
                    <Switch>
                        <Route path="/snake">
                            <Snake />
                        </Route>
                        <Route path="/spiel">
                            <Spiel />
                        </Route>
                        <Route path="/highscore">
                            <HighscoreListe />
                        </Route>
                        <Route path="/eintrag" component={HighscoreEintrag} />
                        <Route path="/" exact component={Einstieg} />
                        <Route path="*" component={GibtsNicht} />
                    </Switch>
                </Router>
            </div>
        )
    }
}