import React, {Component} from 'react'
import axios from 'axios'

import HighscoreDatensatz from './HighscoreDatensatz'
import HighscoreListContainer from './HighscoreListContainer'

interface State {
  highscores: HighscoreDatensatz[];
}

export default class MeineApi extends Component<{}, State> {

  componentDidMount() {
    axios.get('http://tik2.de/spiel1/api-highscore/liste')
         .then((response) => {
           this.setState({
            highscores: response.data
           });
         })  
    }

  render() {
    if (this.state && this.state.highscores) {
      return <HighscoreListContainer highscores={this.state.highscores} />
    } else {
      return 'wartend!';
    }
  }
}