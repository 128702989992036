import React, {Component} from 'react'
import axios from 'axios'

import EintragFormular from './HighscoreEintragFormular'

export default class App extends Component {

  punkte = window.name;
  zeit = new Date
  datum = this.zeit.getDate() + ". " + (this.zeit.getMonth()+1) + "."
  fertigWeiter = false;

  handleEintrag = async (name: string, punkte: string, datum: string, kommentar: string) => {
    const result = axios({
      method: 'POST',
      url: 'http://tik2.de/spiel1/api-highscore/eintrag',
      data: {
        name: name,
        punkte: this.punkte,
        jahr: this.zeit.getFullYear(),
        monat: (this.zeit.getMonth()+1),
        tag: this.zeit.getDate(),
        stunde: this.zeit.getHours(),
        minute: this.zeit.getMinutes(),
        kommentar: kommentar
      }
    })
    window.name = '0';
    window.location.reload();
  };

  render() {
    return (
      <div>
        <b>
        Möchtest Du Deine {this.punkte} Punkte in die Bestenliste eintragen?<br />Daaann.. Auf die Plätze:<br /><br />
        <EintragFormular onEintrag={this.handleEintrag} />
        </b>
      </div>     
    )
  }
}