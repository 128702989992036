import * as React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Link2 from  '@material-ui/core/Link'


export default function Einstieg() {
  return (



    <div align="center">
        <br /><br /><br />
        
        
      <h1>Das (<i>vielleicht</i>) beste Spiel der Welt</h1><br /><br />


       Hier kannst du dich endlich nach Herzenslust voll fressen.<br />
       <small>(bevor ein Virus dich hinwegrafft)</small><br /><br />
       
       <b>  Beeile dich.<br /><br />
       Jetzt. Schnell. Sofort.<br /><br />
       </b>
     
        <Link to="/snake">
          <React.Fragment>
            <Link2 underline="none">
              <Button variant="contained" color="primary">Spiel starten</Button>
            </Link2>
          </React.Fragment>
        </Link> 

        <br /><br />

        Anleitung:<br />
        Bewege die Schlange mit den Pfeiltasten der Tastatur in die entsprechende Richtung.
        (Ich hoffe, das ist nicht zu kompliziert.)
    </div>
    
  );
}