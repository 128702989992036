import React from 'react'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import HighscoreDatensatz from './HighscoreDatensatz'

interface Props {
  highscores: HighscoreDatensatz[];
}

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function HighscoreListe(props: Props) {

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="right">Punkte</StyledTableCell>
            <StyledTableCell align="center">Kommentar</StyledTableCell>
            <StyledTableCell align="right">Datum</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.highscores.map((highscoreDatensatz: HighscoreDatensatz) => (
            <StyledTableRow key={highscoreDatensatz.name}>
              <StyledTableCell component="th" scope="row">
                {highscoreDatensatz.name}
              </StyledTableCell>
              <StyledTableCell align="right">{highscoreDatensatz.punkte}</StyledTableCell>
              <StyledTableCell align="center">{highscoreDatensatz.kommentar}</StyledTableCell>
              <StyledTableCell align="right">{highscoreDatensatz.tag}.{highscoreDatensatz.monat}.{highscoreDatensatz.jahr} um {highscoreDatensatz.stunde} Uhr und {highscoreDatensatz.minute} Min.</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
